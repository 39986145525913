import React from "react";
import "./footer.css";
import { DogLogo, Facebook, Instagram, Twitter } from "../../Assets";

const Footer = () => {
  return (
    <>
      <div className="footer-container">
        <div className="footer-content">
          <div className="logo">
          {/* <img src={DogLogo} alt="Logo"/> */}
          <div className="socialiconsContainer">
              <img src={Facebook} alt="Logo" />
              <img src={Twitter} alt="Logo" />
              <img src={Instagram} alt="Logo" />
            </div>
          </div>

          <div className="social-icons">
            <a href="#facebook" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="#instagram" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#twitter" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
          </div>

          <div className="faqs">{/* Your FAQs content goes here */}</div>
        </div>
      </div>
    </>
  );
};

export default Footer;
