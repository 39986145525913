import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import "./Games.css"; // Update the CSS file name
import "animate.css";


const handleOpenLinkInNewTab = (link) => {
  // Open the link in a new tab
  window.open(link, "_blank");
};

function PlayersCards({ games }) {
  return (
    <>
      <div className="gameslist-container animate__animated animate__fadeIn">
        {games
          .filter((x) => x.player_link)
          .map((item, index) => (
            <div className="black-container">
              <h3>{item.games_name}</h3>

              <img
                src={`https://api-dog.dsmeglobal.com//${item.thumbnail}`}
                alt={item.games_name}
                className="casinoImage"
              />
              <button
                className="yellow-button"
                onClick={() => handleOpenLinkInNewTab(item.player_link)}>
                <FontAwesomeIcon icon={faLink} className="icon" />
                Link
              </button>
            </div>
          ))}
      </div>
    </>
  );
}

export default PlayersCards;
